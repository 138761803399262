<template>
  <div class="CreateClientAccess">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol sm="6">
        <h1> Création de l'accès client à Eclerk.io </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="goBackToClientProfile"
          size="sm"
          shape="pill"
          color="outline-dark">
            <CIcon name="cil-arrow-left"/> Retour au profil client
        </CButton>
      </CCol>
      <CCol>
      </CCol>
    </CRow>

    <CCard class="mt-3" v-if="client.user">
      <CCardHeader>
        <slot name="header">
          <strong>Votre client possède déjà un accès Eclerk</strong>
        </slot>
      </CCardHeader>

      <CCardBody>
        <CRow>
          <CCol sm="6">
            <span>Nom d'utilisateur : </span><strong>{{client.user.username}}</strong>
          </CCol>
          <CCol sm="6">
            <span>Mot de passe : </span><strong>**********</strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="6">
            <span>Prénom du responsable : </span><strong>{{client.user.first_name}}</strong>
          </CCol>
          <CCol sm="6">
            <span>Nom du responsable : </span><strong>{{client.user.last_name}}</strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <span>Email du responsable : </span><strong>{{client.user.email}}</strong>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard class="mt-3" v-else>

      <CCardBody>
        <strong> Création d'un accès client </strong><br>
        <span>Vous pouvez donner à votre client accès à la plateforme Eclerk. Celui-ci pourra alors s'y connecter, accéder au tableau de bord de sa ou ses mission(s) et pourra
          visualiser :
          <ul>
            <li>Les caractéristiques de la mission</li>
            <li>Le(s) colalborateur(s) associé(es) sur la mission ainsi que leur email</li>
            <li>L'avancement de la mission (priorités missions en cours, priorités terminées)</li>
            <li>L'état des factures (montant à payer, etc.</li>
          </ul>
          Celui-ci pourra également récupérer ses reçus de paiement directement sur son espace.
        </span>

        <CForm class="mt-3" v-on:submit.prevent="createClientAccess()">

          <CInput
            label="Identifiant du client"
            type="text"
            maxlength="149"
            placeholder="Identifiant de votre client pour se connecter"
            v-model="createClientAccessUsername"
            @input="$v.createClientAccessUsername.$touch(); isExistingUsername == false"
            :isValid="$v.createClientAccessUsername.$dirty ? !$v.createClientAccessUsername.$error : null"
          >
            <template #description>
              <small v-if="isExistingUsername" class="text-danger">
                {{ createClientAccessUsername }} existe déjà. Veuillez choisir un autre nom d'utilisateur
              </small>
            </template>
          </CInput>

          <CInput
            label="Définissez un mot de passe de connexion"
            type="password"
            maxlength="249"
            placeholder="Mot de passe"
            v-model="createClientAccessPassword"
            @input="$v.createClientAccessPassword.$touch()"
            :isValid="$v.createClientAccessPassword.$dirty ? !$v.createClientAccessPassword.$error : null"
            invalid-feedback="Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial."
          >
          <template #description>
            <small class="text-info"> Votre client pourra changer son mot de passe sur son espace personnel </small>
          </template>
          </CInput>

          <CInput
            label="Confirmez le mot de passe"
            type="password"
            maxlength="249"
            placeholder="Confirmation du mot de passe"
            v-model="createClientAccessConfirmPassword"
            @input="$v.createClientAccessConfirmPassword.$touch()"
            :isValid="$v.createClientAccessConfirmPassword.$dirty ? !$v.createClientAccessConfirmPassword.$error : null"
            invalid-feedback="Veuillez rentrer deux mots de passe identiques"
          >
          </CInput>


          <CRow>
            <CCol sm="6">
              <CInput
                label="Prénom du responsable de connexion chez votre client"
                v-model="createClientAccessFirstName" type="text"
                maxlength="30"
                placeholder="Prénom"
                @input="$v.createClientAccessFirstName.$touch()"
                :isValid="$v.createClientAccessFirstName.$dirty ? !$v.createClientAccessFirstName.$error : null"
                invalid-feedback="Le prénom doit comporter entre 2 et 30 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Nom du responsable de connexion chez votre client"
                v-model="createClientAccessLastName" type="text"
                maxlength="150"
                placeholder="Nom"
                @input="$v.createClientAccessLastName.$touch()"
                :isValid="$v.createClientAccessLastName.$dirty ? !$v.createClientAccessLastName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

          <CInput
            label="Email du responsable de connexion chez votre client"
            type="email"
            maxlength="150"
            placeholder="Email"
            v-model="createClientAccessEmail"
            @input="$v.createClientAccessEmail.$touch();"
            :isValid="$v.createClientAccessEmail.$dirty ? !$v.createClientAccessEmail.$error : null"
          >
            <template #description>
              <small class="text-info"> En cas d'oubli de mot de passe ou d'envoi de mails automatiques</small>
            </template>
          </CInput>

          <CRow class="mt-4">
            <CCol class="text-center">
              <CButton
                type="submit" :color="createClientAccessButtonStyle"
                shape="pill" block class="px-4"
                :disabled="$v.createClientAccessUsername.$invalid || $v.createClientAccessPassword.$invalid || $v.createClientAccessConfirmPassword.$invalid || $v.createClientAccessFirstName.$invalid || $v.createClientAccessLastName.$invalid || $v.createClientAccessEmail.$invalid || createClientAccessInProcess">
                <CSpinner size="sm" label="update profile spinner" v-if="createClientAccessInProcess"></CSpinner>
                {{ createClientAccessButtonText }}
              </CButton>
            </CCol>
          </CRow>
        </CForm>

      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import userMixins from '@/mixins/userMixins'
import { required, minLength, maxLength, email, sameAs} from 'vuelidate/lib/validators'
import { password_validation } from '@/validators/validators'

import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'CreateClientAccess',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
    userMixins
  ],
  data: function () {
    return {

      // ---- CLIENT ---------
      isClientLoading: false,
      client: {
        address: '',
        email: '',
        id: '',
        is_firm: true,
        name: '',
        phone_number: '',
        representant_legal: '',
        siren: '',
      },

      createClientAccessUsername: '',
      createClientAccessPassword: '',
      createClientAccessConfirmPassword: '',
      createClientAccessFirstName: '',
      createClientAccessLastName: '',
      createClientAccessEmail: '',

      createClientAccessButtonText: 'Créer un accès à votre client',
      createClientAccessButtonStyle: 'primary',
      createClientAccessInProcess: false,
      isExistingUsername: false


    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isClientLoading) {
        return true
      }
      return false
    },
  },
  validations: {
    createClientAccessUsername: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(149)
    },
    createClientAccessEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    createClientAccessPassword: {
      required,
      password_validation,
      maxLength: maxLength(249),
    },
    createClientAccessConfirmPassword: {
      required,
      maxLength: maxLength(249),
      sameAsPassword: sameAs('createClientAccessPassword'),
    },
    createClientAccessFirstName: {
      required,
      maxLength: maxLength(30),
      minLength: minLength(2)
    },
    createClientAccessLastName: {
      required,
      maxLength: maxLength(150),
      minLength: minLength(2)
    },
  },

  created: function() {
    this.getClient()
  },


  methods: {
    // ------------- Getters --------------
    getClient () {
      this.isClientLoading = true
      apiUserConnected.getClient(this.token, this.$route.params.id)
      .then((result) => {
        this.client = result.data
        this.createClientAccessUsername = this.client.name.replace(/ /g, '_').toLowerCase() + '_' + String(Math.floor(Math.random()*(1000)))
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isClientLoading = false
      })
    },


    // ---------- Create access client -----------
    createClientAccess () {
      this.createClientAccessButtonText = 'Création en cours'
      this.createClientAccessButtonStyle = 'secondary'
      this.createClientAccessInProcess = true
      apiManagerConnected.createClientAccess(this.token,
        this.client.id, this.createClientAccessUsername, this.createClientAccessEmail, this.createClientAccessFirstName, this.createClientAccessLastName,
        this.createClientAccessPassword, this.createClientAccessConfirmPassword)
      .then(() => {
        this.$router.push('/client-details/' + this.client.id)
      })
      .catch((error) => {
        try {
          if (error.response && error.response.data.username && error.response.data.username[0] === "Un utilisateur avec cet email existe déjà") {
            this.isExistingUsername = true
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        }
        catch {
          this.$store.commit('openGlobalErrorModal')
        }
      })
      .finally(() => {
        this.createClientAccessButtonText = 'Créer un accès à votre client'
        this.createClientAccessButtonStyle = 'primary'
        this.createClientAccessInProcess = false
      })
    },

    // --- Router functions
    goBackToClientProfile () {
      this.$router.push('/client-details/' + this.client.id)
    }
  }
}
</script>
